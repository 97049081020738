<template>
  <v-container>
    <p
      class="section_titles"
      v-text="section_title"
    />
    <v-card
      v-if="!load_general"
      flat
      class="general_wrapper">
      <v-layout
        row
        justify-center
          wrap>
          <v-flex
            class="flex_general"
            xs12
            sm6>
            <v-text-field
              outlined
              label="Correo"
              background-color="white"
              color="primary"
              v-model="general.email"
            />
          </v-flex>
          <v-flex
            class="flex_general"
            xs12
            sm6>
            <v-text-field
              outlined
              label="Teléfono"
              background-color="white"
              color="primary"
              v-model="general.phone"
            />
          </v-flex>
          <v-flex
            class="flex_general"
            xs12
            sm6>
            <v-text-field
              outlined
              label="Dirección"
              background-color="white"
              color="primary"
              v-model="general.address"
            />
          </v-flex>
          <v-flex
            class="flex_general"
            xs12
            sm6>
            <v-text-field
              outlined
              label="slogan"
              background-color="white"
              color="primary"
              v-model="general.slogan"
            />
          </v-flex>
      </v-layout>
    </v-card>
    <v-card
    v-else
      flat
      color="transparent">
      <v-container
        style="margin-top: 20%">
        <v-layout
          row
          justify-center>
          <loading-animation />
        </v-layout>
      </v-container>
    </v-card>
    <div
      class="btn_send_wrapper">
      <v-tooltip
        bottom>
        <template 
          v-slot:activator="{ on }"
        >
          <span 
            v-on="on">
            <v-btn
              :disabled="load_general"
              @click="post_general"
              large
              class="btn_submit"
              icon>
              <v-icon
                color="primary"
                v-html="sumbitIcon"
              />
            </v-btn>
          </span>
        </template>
        <span
          v-html="'Confirmar cambios'"
        />
      </v-tooltip>
    </div>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    load_general: false,
    sumbitIcon: 'mdi-send',
    general: {},
    section_title: 'General'
  }),
  mounted () {
    this.get_general()
  },
  computed: {
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    }
  },
  methods: {
    get_general () {
      this.load_general = true
      db.get(`${uri}/api/v1/general/`, {})
        .then((response) => {
          this.load_general = false
          this.general = response.data
        })
        .catch((error) => {
          this.load_general = false
          this.$store.commit('toggle_alert', {color: 'fail', text: 'Hubo un error con la conexión. Favor de intentarlo más tarde.'})
        })
    },
    post_general () {
      this.load_general = true
      var body = new URLSearchParams()
      body.append('email', this.general.email)
      body.append('phone', this.general.phone)
      body.append('address', this.general.address)
      body.append('slogan', this.general.slogan)
      db.put(`${uri}/api/v1/general/`, body, {
        headers: {
          'Authorization': this.usr_token
        }
      })
        .then((response) => {
          this.load_general = false
          this.general = response.data
          this.$store.commit('toggle_alert', {color: 'success', text: "Se actualizó la información correctamente."})
        })
        .catch((error) => {
          this.load_general = false
          this.$store.commit('toggle_alert', {color: 'fail', text: error.response.data.message})
        })
    }
  }
}
</script>
<style scoped>
.btn_submit {
  margin: 0px !important
}
.flex_general {
  padding: 5px;
}
.btn_send_wrapper {
  -webkit-box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75);
  box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75); 
  border-radius: 100%;
  border-style: solid;
  border-color: #21345C;
  z-index: 10;
  position:fixed;
  bottom:0;
  right:0;
  margin-right: 10%;
  margin-bottom: 5%
}
.general_wrapper {
  background-color: transparent;
  width: 100%
}
</style>